@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");

:root {
  --primary: #1f51ff;
  --secondary: #15f4ee;
  --color-gradient-primary: -webkit-linear-gradient(#1f51ff, #15f4ee);
}

body {
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-weight: normal;
  font-style: normal;
  font-size: 13px;
  background-color: #0f172a;
  color: snow;
  display: flex;
  height: 90vh;
  flex-direction: column;
  align-items: center;
}

main {
  position: relative;
  margin: 6rem auto;
  position: relative;
  display: flex;
  max-width: 800px;
  // background-color: #15f4ee;
  // padding: 1rem;
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
}

.contacts {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0.25rem;
  margin-top: 0.5rem;

  a {
    color: silver;
    transition: color 0.5s ease;
    &:hover {
      color: var(--primary);
    }
  }
}

.description {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  .profile-img {
    border-radius: 50%;
  }
}

.author-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  p {
    font-size: 18px;
    font-weight: 400;
    margin: 0rem 0;
  }
}

.projects {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
}

.projects-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  column-gap: 1rem;
  row-gap: 1rem;
}

.card,
.card-error {
  background-color: #232e40;
  padding: 1.5rem 2rem;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  p {
    margin: 0;
    color: silver;
  }
}

.card-error {
  grid-column-start: 1;
  grid-column-end: 3;
  background-color: transparent;
  border: 2px solid tomato;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1rem;
  p {
    color: tomato;
    font-size: 14px;
  }
}

.project-logo {
  height: 100%;
  min-width: 80px;
  display: flex;
  align-items: center;
}

.project-content {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 0.5rem;
}

.project-1st-line {
  display: flex;
  column-gap: 0.5rem;
  align-items: center;

  .avatar {
    border-radius: 8px;
    border: 1px solid var(--primary);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

  .title {
    font-size: 24px;
    font-weight: 700;
  }
}
.project-2st-line {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;

  .actions {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: row;
    column-gap: 0.5rem;
  }
}

.h1 {
  font-size: 32px;
  font-weight: 700;
}

.h2 {
  font-size: 28px;
  font-weight: 700;
}

.h3 {
  font-size: 24px;
  font-weight: 700;
}
.h4 {
  font-size: 20px;
  font-weight: 700;
}

.primary {
  background: var(--color-gradient-primary);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.loader {
  width: 360px;
  height: 100px;
  display: block;
  position: relative;
  background-image: linear-gradient(
      100deg,
      transparent,
      rgba(38, 50, 56, 0.5) 50%,
      transparent 80%
    ),
    linear-gradient(#fff 20px, transparent 0),
    linear-gradient(#fff 20px, transparent 0),
    linear-gradient(#fff 20px, transparent 0);
  background-repeat: no-repeat;
  background-size: 75px 100px, 125px 20px, 260px 20px, 260px 20px;
  background-position: 0% 0, 120px 0, 120px 40px, 120px 80px;
  box-sizing: border-box;
  animation: animloader 1s linear infinite;
}

.loader::after {
  content: "";
  box-sizing: border-box;
  width: 100px;
  height: 100px;
  border-radius: 8px;
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
}

@keyframes animloader {
  0% {
    background-position: 0% 0, 120px 0, 120px 40px, 120px 80px;
  }
  100% {
    background-position: 100% 0, 120px 0, 120px 40px, 120px 80px;
  }
}

.badge {
  padding: 0.25rem 0.75rem;
  font-size: 10px;
  border-radius: 10px;
  font-weight: 700;
  background-color: white;
  color: #1f51ff;
  width: fit-content;
}

.badge.js {
  background-color: #f0db4f;
  color: black;
}

.badge.cpp {
  background-color: snow;
  color: black;
}

.badge.gn {
  background-color: black;
  color: white;
}

button,
a.btn,
a.btn-2nd {
  border: none;
  width: fit-content;
  background-color: #1f5bff;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  text-decoration: none;
  font-size: inherit;
  color: inherit;
  transition: background-color 0.5s ease;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0.25rem;
  &:hover {
    cursor: pointer;
    background-color: #002cc8;
  }
  &.err {
    background-color: tomato;
  }
  &.err:hover {
    background-color: firebrick;
  }
}

a.btn-2nd {
  color: #232e40;
  background-color: var(--secondary);
  &:hover {
    cursor: pointer;
    background-color: #08b1ac;
  }
}

button {
  font-weight: 700;
}

@media only screen and (max-width: 600px) {
  .projects-list {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    column-gap: 1rem;
    row-gap: 1rem;
  }
  .description {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    align-items: center;
  }

  .author-info {
    align-items: center;
    p {
      text-align: center;
    }
  }

  .h1 {
    font-size: 28px;
  }

  .h2 {
    font-size: 24px;
  }
}

// /* Small devices (portrait tablets and large phones, 600px and up) */
// @media only screen and (min-width: 600px) {
//   main {
//     // background-color: red;
//   }
// }

// /* Medium devices (landscape tablets, 768px and up) */
// @media only screen and (min-width: 768px) {
//   main {
//     // background-color: yellow;
//   }
// }

// /* Large devices (laptops/desktops, 992px and up) */
// @media only screen and (min-width: 992px) {
//   main {
//     // background-color: green;
//   }
// }

// /* Extra large devices (large laptops and desktops, 1200px and up) */
// @media only screen and (min-width: 1200px) {
//   main {
//     // background-color: orange;
//   }
// }
